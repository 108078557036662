<template>
  <div class="row">
    <form @submit.prevent="filterResult(null)" class="w-100">
      <div class="col-md-12 px-7">
        <b-card class="mb-7 row" id="filter-card">
          <div class="col-lg-12 col-xl-12 px-0">
            <div class="row align-items-end py-0 my-0 mx-0">
              <div class="col-lg-6 col-md-5 col-sm-12 my-2 row px-0 mx-0 d-flex justify-content-start items-center" >
                <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)">
                  <i class="fa fa-chevron-left"></i>
                </span>
                <input-filter
                    :helper-text="$t('general.search')"
                    title="general.search"
                    :placeholder="$t('general.search_filter')"
                    :model.sync="searchProxy"
                ></input-filter>
              </div>
              <div class="col-lg-5 col-md-5 my-lg-2 my-sm-0 row mx-0 px-0">
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                      :helper-text="$t('general.start_date')"
                      title="general.start_date"
                      :model.sync="startDateProxy"
                      name="start_time">
                  </date-filter>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 my-2 my-md-0">
                  <date-filter
                      :helper-text="$t('general.end_date')"
                      title="general.end_date"
                      :startDate="startDateProxy"
                      :model.sync="endDateProxy"
                      name="end_date">
                  </date-filter>
                </div>
              </div>
              <div
                  class="col-xl-1 my-lg-1 col-md-2 col-sm-12 mt-3 px-1 d-flex align-items-center justify-content-end m-0 pr-0 row"
                  style="margin: 0 !important;">
                <div class="col-12 pr-0">
                  <button @click="filterResult(null)"
                          class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1">{{
                      $t('general.filter')
                    }}
                  </button>
                </div>
                <div class="col-12 pr-0">
                  <button @click="resetFilters"
                          class="w-100 btn btn-sm btn-outline-primary rounded-full font-weight-bold mt-2 py-1">
                    {{ $t('general.clear') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </form>

    <div class="col-md-12">
      <dashboard-box :back="true" back-component-name="sample" class="pt-3">
        <template v-slot:title>
          <span>{{ $t('general.meetings') }}</span>
        </template>
        <template v-slot:preview>
          <b-row class="pt-5 pl-5 pr-5 pb-5">
            <template v-if="meetingList.length !== 0">
              <list-columns-single v-for="(item, index) in meetingList" :key="index"
                                   :createdAt="item.date_hour | momentDateFormat"
                                   :id="item.id"
                                   :actions="false"
                                   :name="item.name"
                                   :createdBy="item.user.name"
                                   :company-name="item.company.name"
                                   @onClickCard="onClickMeetingCard"
              ></list-columns-single>
            </template>
            <template v-else>
              <h3 class="text-center mt-5 d-block w-100">{{ $t('general.no_records_found') }}</h3>
            </template>
          </b-row>
        </template>
      </dashboard-box>
     </div>
  </div>
</template>

<script>


import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters, mapMutations} from 'vuex';
import {
  MEETINGS,
  ERROR,
  LOADING,
  GET_MEETINGS,
  MEETINGS_LIST,
  SET_PAGE_NUMBER,
  SET_PER_PAGE,
  PAGE_NUMBER,
  PER_PAGE,
  SEARCH,
  START_DATE,
  END_DATE,
  SET_SEARCH,
  SET_START_DATE,
  SET_END_DATE,
  RESET_FILTERS
} from "@/core/services/store/sample/create/meeting-list.module";

// components
import DashboardBox from "@/assets/components/DashboardBox";
import MeetingListItem from "@/view/pages/samples/layout/MeetingListItem";
import paginate from "@/assets/components/dataTable/Pagination";
import DateFilter from "@/assets/components/filters/DateFilter";
import InputFilter from "@/assets/components/filters/InputFilter";
import ListColumnsSingle from "@/assets/components/listColumns/listColumnsSingle";

export default {
  name: "ListMeeting",
  components: {
    DashboardBox,
    MeetingListItem,
    paginate,
    DateFilter,
    InputFilter,
    ListColumnsSingle
  },
  computed: {
    ...mapGetters({
      meetings: MEETINGS,
      error: ERROR,
      loading: LOADING,
      meetingList: MEETINGS_LIST,

      // FILTERS
      pageNumber: PAGE_NUMBER,
      perPage: PER_PAGE,
      search: SEARCH,
      startDate: START_DATE,
      endDate: END_DATE,
    }),
    pageProxy: {
      set(value) {
        this.setPage(value);
        this.$store.dispatch(GET_MEETINGS);
      },
      get() {
        return this.pageNumber;
      }
    },
    perPageProxy: {
      set(value) {
        this.setPage(1);
        this.setPerPage(value);
        this.$store.dispatch(GET_MEETINGS);
      },
      get() {
        return this.perPage;
      }
    },
    searchProxy: {
      set(value) {
        this.setSearch(value);
      },
      get() {
        return this.search;
      }
    },
    startDateProxy: {
      set(value) {
        this.setStartDate(value);
      },
      get() {
        return this.startDate;
      }
    },
    endDateProxy: {
      set(value) {
        this.setEndDate(value);
      },
      get() {
        return this.endDate;
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations({
      setPage: SET_PAGE_NUMBER,
      setPerPage: SET_PER_PAGE,
      setSearch: SET_SEARCH,
      setStartDate: SET_START_DATE,
      setEndDate: SET_END_DATE,
    }),
    onClickMeetingCard(id) {
      // need to redirect to meeting detail page to process next step
      this.$router.push({name: 'sample.create.meeting.detail', params: {id}})
    },
    resetFilters(){
      this.$store.dispatch(RESET_FILTERS);
    },
    filterResult(){
      this.$store.dispatch(GET_MEETINGS);
    }
  },
  created(){
    this.$store.dispatch(RESET_FILTERS);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t('general.sample'), route: "sample"},
      {title: this.$t('general.meeting_list')}
    ]);

    if(!this.isUserGranted('Sample', ['create'])) {
      return false;
    }

    this.$store.dispatch(GET_MEETINGS);
  },
}
</script>

<style scoped>
*:focus{
  outline: none;
}
.card-body{
  padding: 1rem;
}
.meeting-filter {
  width: 100%;
}
.card-body{
  padding-top: 0 !important;
  padding-bottom: 0 !important;

}
#filter-card{
  background-color: transparent !important;
  border: none;
  box-shadow: none;
}
</style>
