<template>
  <div class="card-body shadow-sm rounded-lg px-5 pt-3 pb-0 mb-4 cursor-pointer"
       @click="$emit('onClickCard', id)"
       style="border:1px solid rgba(209, 213, 219, 0.5);"
  >
    <!--begin::Top-->
    <div class="">
      <div class="d-flex justify-items-center">
        <div class="">
          <img style="width: 30px; height: auto; object-fit: cover;" alt="Pic"
               :src="mediaBase + '/icon/meeting.png'">
        </div>
        <div class="d-flex mb-0 justify-content-between justify-items-center w-100" style="align-items: center !important;">
          <p class="m-0 px-4 text-h3" style="font-size: 20px;"><b>T0001</b> - Lorem ipsum dolor sit amet, consectetur.</p>
          <p class="mb-0" style="line-height: 20px;"><i class="fas fa-calendar pr-2"></i> 12/12/1121</p>
        </div>
      </div>
      <div class="d-flex justify-content-between items-end align-baseline justify-content-end pb-2">
        <p class="mb-0 mt-1">Created By: Ahmet</p>
        <p class="mb-0" style="font-size: 18px">Zara</p>
      </div>
    </div>

    <!--end::Top-->
    <!--begin::Separator-->
    <!--end::Bottom-->
  </div>
</template>

<script>
export default {
  name: "MeetingListItem",
  props: {
    id: {
      required: true,
    },
    name: {
      default: "No Name"
    },
    createdAt: {
      required: true,
    },
    createdBy: {
      required: true,
    },
    companyName: {
      required: true,
    }
  },
}
</script>

<style scoped>
.font-3xl {
  font-size: 1.4rem !important;
}
</style>