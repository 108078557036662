import { render, staticRenderFns } from "./listColumnsSingle.vue?vue&type=template&id=28999394&scoped=true&"
import script from "./listColumnsSingle.vue?vue&type=script&lang=js&"
export * from "./listColumnsSingle.vue?vue&type=script&lang=js&"
import style0 from "./listColumnsSingle.vue?vue&type=style&index=0&id=28999394&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28999394",
  null
  
)

export default component.exports